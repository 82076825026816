import { Controller } from "@hotwired/stimulus"
import { addClass, removeClass } from "../lib/helpers"

export default class extends Controller {
  initialize() {
    this.addOverflowY = this.addOverflowY.bind(this)
    this.removeOverflowY = this.removeOverflowY.bind(this)
  }

  connect() {
    this.element.addEventListener("mouseover", this.addOverflowY)
    this.element.addEventListener("mouseout", this.removeOverflowY)
  }

  disconnect() {
    this.element.removeEventListener("mouseover", this.addOverflowY)
    this.element.removeEventListener("mouseout", this.removeOverflowY)
  }

  addOverflowY() {
    addClass(this.element, "scrollable-slide")
  }

  removeOverflowY() {
    removeClass(this.element, "scrollable-slide")
  }
}
