import { coerce } from "./helpers"

export class StoredSetting {
  static storage = window.localStorage

  static remove(settingKey, settingName) {
    return this.storage.removeItem(`${settingKey}-${settingName}`)
  }

  static set(settingKey, settingName, value) {
    return this.storage.setItem(`${settingKey}-${settingName}`, value)
  }

  static get(settingKey, settingName, opts = {}) {
    return coerce(
      this.storage.getItem(`${settingKey}-${settingName}`) || opts.default
    )
  }
}