window.scrolledToAnchor = false
import "trix"
import "@rails/actiontext"
import "@hotwired/turbo-rails"

import "../assets/stylesheets/application.scss"

import "./setup/mrujs"
import "./setup/cable_ready_operations"
import "./setup/stripe"
import "./setup/shoelace"
import "./setup/log_events"
import "./controllers/"

document.addEventListener('turbo:before-fetch-request', async (event) => {
  event.preventDefault()

  event.detail.fetchOptions.headers["TURBO_FETCH"] = true

  event.detail.resume()
})

