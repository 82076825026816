import { Controller } from "@hotwired/stimulus"
import { coerce } from "../lib/helpers"
import debounce from "lodash/debounce"

MAX_WIDTH_PERCENT = 0.8

export default class extends Controller {
  static values = {
    name: {
      type: String
    },
    initialWidth: {
      type: Number,
      default: 400
    },
    minimumWidth: {
      type: Number,
      default: 250
    }
  }

  static targets = ["control", "resizable"]

  initialize() {
    this.mouseDownListeners = this.mouseDownListeners.bind(this)
    this.windowResize = debounce(this.windowResize.bind(this), 10)
    this.resize = debounce(this.resize.bind(this), 10)
    this.stopResize = this.stopResize.bind(this)
  }

  connect() {
    this.setWidth(this.rememberedWidth)
    this.controlTarget.addEventListener("mousedown", this.mouseDownListeners)
    window.addEventListener("resize", this.windowResize)
  }

  disconnect() {
    this.controlTarget.removeEventListener("mousedown", this.mouseDownListeners)
    window.removeEventListener("resize", this.windowResize)
  }

  ////

  mouseDownListeners(event) {
    event.preventDefault()
    window.addEventListener("mousemove", this.resize)
    window.addEventListener("mouseup", this.stopResize)
  }

  resize(event) {
    let targetWidth = this.pageWidth - event.pageX

    this.setWidth(targetWidth)
  }

  windowResize(_event) {
    this.setWidth(this.rememberedWidth)
  }

  setWidth(value) {
    if (value <= this.minimumWidthValue) { value = this.minimumWidthValue }
    if (value >= this.maximumWidthValue) { value = this.maximumWidthValue }

    this.rememberedWidth = value
    this.resizableTarget.style.width = value + "px"
  }

  get pageWidth() {
    return document.body.getBoundingClientRect().width
  }

  stopResize() {
    window.removeEventListener("mousemove", this.resize)
    window.removeEventListener("mouseup", this.stopResize)
  }

  get rememberedWidth() {
    return coerce(
      this.storage.getItem(this.nameValue)
    ) || this.initialWidthValue
  }

  set rememberedWidth(value) {
    this.storage.setItem(this.nameValue, value.toString())
  }

  get maximumWidthValue() {
    return Math.floor(this.pageWidth * MAX_WIDTH_PERCENT)
  }

  get storage() {
    return window.localStorage
  }
}