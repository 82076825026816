import { Controller } from "@hotwired/stimulus"
import { log } from "../lib/log"

// If you want to trigger showing a message via time spent on a page, consider
// the following example:
// <div data-controller="helpscout-message"
//      data-helpscout-message-message-id-value="9fc44db6-cea4-41a1-b8af-10243a433df6"
//      data-helpscout-message-delay-value="60000"
//      data-helpscout-message-force="true">
// </div>

// If you want to trigger showing a message via a link, button, or other action, consider
// the following example:
// <div data-controller="helpscout-message"
//      data-helpscout-message-message-id-value="9fc44db6-cea4-41a1-b8af-10243a433df6">
//   <a data-action="click->helpscout-message#showMessage"></a>
// </div>


export default class extends Controller {
  static values = {
    messageId: String,
    delay: Number,
    force: Boolean
  }

  initialize() {
    this.showMessage = this.showMessage.bind(this)
  }

  connect() {
    if (this.delayValue) {
      this._initialDelay =
        setTimeout(
          this.showMessage,
          this.delayValue
        )
    }
  }

  showMessage() {
    if (window.Beacon) {
      window.Beacon("show-message", this.messageIdValue, { force: this.forceValue })
      return
    }


    log("Beacon is not available yet...")
    this._retryingDelay = setTimeout(this.showMessage, 1_000)
  }

  disconnect() {
    if (this._initialDelay) {
      clearTimeout(this._initialDelay)
      this._initialDelay = null
    }

    if (this._retryingDelay) {
      clearTimeout(this._retryingDelay)
      this._retryingDelay = null
    }
  }
}
