import { Controller } from "@hotwired/stimulus";
import { show, hide } from "../lib/helpers";

export default class extends Controller {
  static targets = ["checkboxGroup", "error"]
  static values = { required: Number }

  connect() {
    hide(this.errorTarget)
    if (!this.hasRequiredValue) {
      this.requiredValue = 1
    }
  }

  validate(event) {
    const checkboxes = this.checkboxGroupTarget.querySelectorAll('input[type="checkbox"]')
    const checkedCount = Array.from(checkboxes).filter(checkbox => checkbox.checked).length;

    if (checkedCount < this.requiredValue) {
      event.preventDefault()
      show(this.errorTarget)
    } else {
      hide(this.errorTarget)
    }
  }
}
