import { Controller } from "@hotwired/stimulus"
import { MetaVars } from "../lib/helpers"

const findFirstMatchingAncestor = (rootElement, matcherFn) => {
  if (matcherFn(rootElement)) return rootElement
  if (rootElement.parentElement) return findFirstMatchingAncestor(rootElement.parentElement, matcherFn)

  return null
}

export default class extends Controller {
  static values = {
    articles: String,
    term: String,
    articleType: String,
    articleId: String,
    route: String
  }

  connect() {
    if (!window.Beacon) return

    window.Beacon('init', '9a511ebb-b4d1-489d-9ea7-3ba8bbc6d023')

    if (MetaVars.userId && MetaVars.beaconSignature) {
      window.Beacon('identify', {
        name: MetaVars.userName,
        email: MetaVars.userEmail,
        signature: MetaVars.beaconSignature
      })
    }
  }

  open(event) {
    this.preventDefaultHandler(event)

    window.Beacon('open')
  }

  close(event) {
    this.preventDefaultHandler(event)

    // Don't interfere with beacon's close button handler
    if (findFirstMatchingAncestor(event.target, e => e && e.dataset && e.dataset.cy === "beacon-close-button")) return

    window.Beacon('close')
  }

  toggle(event) {
    this.preventDefaultHandler(event)

    window.Beacon('toggle')
  }

  suggest(event) {
    this.preventDefaultHandler(event)

    window.Beacon('search', this.articlesValue)
  }

  search(event) {
    this.preventDefaultHandler(event)

    window.Beacon('search', this.termValue)
  }

  article(event) {
    this.preventDefaultHandler(event)

    const type = this.articleTypeValue
    const articleId = this.articleIdValue

    window.Beacon('article', articleId, { type })
  }

  navigate(event) {
    this.preventDefaultHandler(event)

    const route = this.routeValue
    window.Beacon('navigate', route)
  }

  preventDefaultHandler(event) {
    const { currentTarget } = event

    const isBodyTag = currentTarget.tagName !== "BODY"
    const targetIsElement = currentTarget === this.element

    if (isBodyTag && targetIsElement) {
      event.preventDefault()
    }
  }

}