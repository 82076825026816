import { Controller } from "@hotwired/stimulus"

const preventEventDefault = event => event.preventDefault()
const removeAttachment = event => event.attachment.remove()

const removeTrailingNewlines = (event) => {
  const element = event.currentTarget

  if (element.innerHTML.match(/<br><\/div>$/)) {
    element.innerHTML = element.innerHTML.slice(0, -10) + "</div>"
    removeTrailingNewlines(event)
  }
}

const removeTrailingWhitespace = (event) => {
  const element = event.currentTarget

  if (element.innerHTML.match(/&nbsp;<\/div>$/)) {
    element.innerHTML = element.innerHTML.slice(0, -12) + "</div>"
    removeTrailingWhitespace(event)
  } else if (element.innerHTML.match(/&nbsp; <\/div>$/)) {
    element.innerHTML = element.innerHTML.slice(0, -13) + "</div>"
    removeTrailingWhitespace(event)
  }
}

const removeTrailingWhitespaceAndNewLines = (event) => removeTrailingNewlines(event) && removeTrailingWhitespace(event)

const LINK_PATTERN_STRING = "^((http|https):\/\/.*$|mailto:.*)$"
const LINK_PATTERN = RegExp(LINK_PATTERN_STRING, "i")
export default class extends Controller {
  static values = {
    configure: {
      type: String,
      default: ""
    },
    linkErrorMessage: {
      type: String
    }
  }

  initialize() {
    this.checkRequiredContent = this.checkRequiredContent.bind(this)
    this.validate = this.validate.bind(this)
  }

  connect() {
    this.configure()
    this.checkRequiredContent()  // Initial check for required content validity
  }

  configure() {
    this.element.setAttribute("autocomplete", "off")

    !this.configurationIncludes("enable_fileupload") && this.disableUploads()
    !this.configurationIncludes("disable_trim") && this.enableTrim()
    this.required && this.element.addEventListener("trix-change", this.checkRequiredContent)

    this.urlInputElement.pattern = LINK_PATTERN_STRING
    this.urlInputElement.addEventListener("change", this.validate)
    this.urlInputElement.addEventListener("keyup", this.validate)
    this.urlInputElement.addEventListener("blur", this.validate)
    this.linkAndUnlinkButtons.forEach(e => e.addEventListener("click", this.validate))
  }

  configurationIncludes(setting) {
    return this.configureValue.includes(setting)
  }

  disableUploads() {
    // disable file attachments from being accepted
    this.element.addEventListener("trix-file-accept", preventEventDefault)

    // if somehow an attachment does get added, remove anyway
    this.element.addEventListener("trix-attachment-add", removeAttachment)
  }

  enableTrim() {
    this.element.addEventListener("blur", removeTrailingWhitespaceAndNewLines)
  }

  disconnect() {
    this.element.removeEventListener("trix-file-accept", preventEventDefault)
    this.element.removeEventListener("trix-attachment-add", removeAttachment)
    this.element.removeEventListener("blur", removeTrailingWhitespaceAndNewLines)
    this.element.removeEventListener("trix-change", this.checkRequiredContent)
    this.urlInputElement.removeEventListener("change", this.validate)
    this.urlInputElement.removeEventListener("keyup", this.validate)
    this.urlInputElement.removeEventListener("blur", this.validate)
    this.linkAndUnlinkButtons.forEach(e => e.removeEventListener("click", this.validate))
  }

  checkRequiredContent() {
    if (!this.required) return

    if (this.editorContent.length > 0) {
      this.element.classList.remove("trix-invalid")
    } else {
      this.element.classList.add("trix-invalid")
    }
  }

  validate() {
    if (LINK_PATTERN.test(this.urlInputElement.value)) {
      this.urlInputElement.setCustomValidity("")
    } else {
      this.urlInputElement.setCustomValidity(this.linkErrorMessageValue)
    }

    this.urlInputElement.reportValidity()
  }

  get editorContent() {
    return this.element.editor.getDocument().toString().trim()
  }

  get required() {
    return this.element.getAttribute("required") == "required"
  }

  get urlInputElement() {
    return this.toolbar.querySelector("input[data-trix-input]")
  }

  get toolbar() {
    return this.element.parentElement.querySelector("trix-toolbar")
  }

  get linkAndUnlinkButtons() {
    return Array.from(this.toolbar.querySelectorAll(".trix-dialog__link-fields input[type=button]"))
  }
}