import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import mrujs from "mrujs"
import CableReady from "cable_ready"

export default class extends Controller {
  static targets = ["item", "parent"]
  static values = {
    url: String,
    redirectUrl: String,
    handleSelector: String
  }

  initialize() {
    if (!this.redirectUrlValue) {
      throw new Error("redirectUrl value was not provided")
    }

    this.handleDragStart = this.handleDragStart.bind(this)

    this.sortable = Sortable.create(this.parent, {
      animation: 150,
      handle: this.handleSelectorValue,
      ghostClass: 'sortable-ghost',
      onStart: this.onStart.bind(this),
      onEnd: this.onEnd.bind(this),
    })

    this.element.addEventListener('dragstart', this.handleDragStart)
  }

  get parent() {
    if (this.hasParentTarget) { return this.parentTarget }

    return this.element
  }

  disconnect() {
    // for some reason, nested sortables will disconnect
    // during dragging. So do not tear down sortables
    this.element.removeEventListener('dragstart', this.handleDragStart)
  }

  get itemsGlobalIds() {
    return Array.from(this.itemTargets).map(item => item.dataset.dragGlobalId)
  }

  onStart(_event) {
    this._onStartGlobalIds = this.itemsGlobalIds
  }

  onEnd(event) {
    const anchor = event.item.id || event.item.querySelector("[id]")?.id
    this.updateItems({ anchor })
    this.cleanUp()
  }

  handleDragStart(event) {
    const draggedElement = event.target.closest('.draggable-ownership')
    const documentId = draggedElement?.dataset?.docId

    if (documentId) {
      const dataToTransfer = JSON.stringify({
        documentId: documentId
      })

      event.dataTransfer.setData("application/json", dataToTransfer)
    } else {
      console.error("Document ID not found for the dragged item.")
    }
  }

  cleanUp() {
    this._onStartGlobalIds = null
  }

  updateItems({ anchor }) {
    const data = {
      items: this.itemsGlobalIds,
      redirectUrl: this.redirectUrlValue,
      anchor
    }

    mrujs
      .fetch(this.urlValue, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      })
      .then(r => r.json())
      .then(CableReady.perform)
  }
}
