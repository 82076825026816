import { Controller } from "@hotwired/stimulus"
import { getUrlParam, addClass, removeClass } from "../lib/helpers"

export const shake = (element, lengthInMs = 1000) => {
  addClass(element, "shake")
  setTimeout(() => removeClass(element, "shake"), lengthInMs)
}

export default class extends Controller {
  static values = {
    paramKey: String,
    paramValue: String,
    shakeLength: {
      type: Number,
      default: 500
    }
  }

  connect() {
    const paramKey = this.paramKeyValue
    const paramValue = this.paramValueValue
    const shakeLength = this.shakeLengthValue

    if (getUrlParam(paramKey) === paramValue) {
      const shakeFileSelector = () => shake(this.element)

      setTimeout(shakeFileSelector, shakeLength)
    }
  }
}