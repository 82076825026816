import { Controller } from "@hotwired/stimulus"
import DirtyForm from "../lib/dirty_form"

export default class extends Controller {
  static values = {
    message: String
  }

  connect() {
    const message = this.messageValue

    this._dirty_form = new DirtyForm(this.element, { message })
  }

  disconnect() {
    this._dirty_form.tearDown()
  }
}