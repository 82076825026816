import { BaseModal } from "./base_modal"
import { Flash } from "../lib/flash"

const elementValue = e => e.value
const emptyStrings = v => !!v
const first = values => values[0]

export default class extends BaseModal {
  static targets = ["ownerSegment"]
  static values = {
    successContentMessage: String
  }

  triggerOpenModal(event) {
    const isMatchingEvent = event.detail.matchingController.split(" ").includes(this.identifier)

    if (!isMatchingEvent) { return }

    this._triggeringElement = event.target
    this.bsModal.show()
  }

  addAndClose(event) {
    event.preventDefault()

    this.add(event)
    this.bsModal.hide()
  }

  add(event) {
    event.preventDefault()

    // collect all ownerSegment targets, reverse order (based on our desired precedence), and then remove empty strings
    const selectedValuesInOrder = this.ownerSegmentTargets.map(elementValue).reverse().filter(emptyStrings)
    const selectedValue = first(selectedValuesInOrder)

    const { slimSelect } = this._triggeringElement

    this.flashSuccess()

    slimSelect.setSelected([
      ...slimSelect.getSelected(),
      selectedValue
    ])

    this._resetMultiSelects()
  }

  flashSuccess() {
    Flash.success(
      this.successContentMessageValue
    )
  }
}