import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.pertinentLink = this.pertinentLink.bind(this)
    this.isNonDomainLink = this.isNonDomainLink.bind(this)
    this.isDesiredExternal = this.isDesiredExternal.bind(this)
    this.makeExternalLink = this.makeExternalLink.bind(this)
  }

  connect() {
    this.addClassToActiveLinks()
  }

  addClassToActiveLinks() {
    const allLinks = Array.from(document.querySelectorAll("a[href]"))

    allLinks
      .filter(this.pertinentLink)
      .forEach(this.makeExternalLink)
  }

  get currentDomain() {
    if (!this._currentDomain) {
      this._currentDomain = window.location.hostname.split('.').slice(-2).join('.')
    }

    return this._currentDomain
  }

  pertinentLink(link) {
    return (this.isNonDomainLink(link) || this.isDesiredExternal(link))
  }

  isNonDomainLink(link) {
    const hrefAttr = link.getAttribute("href")
    const { href } = link

    if (hrefAttr === "" || hrefAttr == "#" || !href) return // non-link equivalents

    return !href.includes(this.currentDomain)
  }

  isDesiredExternal(link) {
    const isExternal = link.getAttribute("external")

    return !!isExternal
  }

  makeExternalLink(link) {
    link.target = "_blank"
    link.rel = "noopener noreferrer"
  }
}