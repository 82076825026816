import { Controller } from "@hotwired/stimulus"
import { MetaVars } from "../lib/helpers"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  static values = {
    placement: String
  }
  connect() {
    this.setupTooltip()
  }

  disconnect() {
    bootstrap.Tooltip.getInstance(this.element)?.dispose()
  }

  setupTooltip() {
    if (MetaVars.envTest) return

    new bootstrap.Tooltip(this.element, {
      placement: this.placementValue,
      html: true
    })
  }
}
