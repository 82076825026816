import { BaseModal } from "./base_modal"
import { hide, show, addClass, removeClass, clearUrlParams, addUrlParams } from "../lib/helpers"

const SELECTED_CLASS = "selected"
const deselect = e => removeClass(e, SELECTED_CLASS)
const select = e => addClass(e, SELECTED_CLASS)

export default class extends BaseModal {
  static targets = [
    "statusSlider",
    "statusText",
    "statusCircle",
    "completionSlider",
    "goalId"
  ]

  showModal(event) {
    super.showModal(event)

    this.setUrlParams()
    this.setupStatusSlider()
    this.setupCompletionSlider()
  }

  hideModal(event) {
    super.hideModal(event)
    clearUrlParams()
  }

  // Support functions
  setUrlParams() {
    const goalId = this.goalIdTarget.value

    addUrlParams({
      trigger_action: "click",
      trigger_selector: `#goal_${goalId} .status-circle`
    })
  }

  setupStatusSlider() {
    const statusValue = this.statusSliderTarget.value

    this.updateStatusSlider(statusValue)
    this.updateStatusCircles(statusValue)
  }

  setupCompletionSlider() {
    const completionValue = this.completionSliderTarget.value

    this.updateCompletionSlider(completionValue)
  }

  statusPick(event) {
    const { index } = event.currentTarget.dataset

    this.updateStatusSlider(index)
    this.updateStatusCircles(index)
  }

  statusSlide(event) {
    const { value } = event.currentTarget

    this.updateStatusSlider(value)
    this.updateStatusCircles(value)
  }

  updateStatusSlider(value) {
    // Given a value 0-4
    this.statusSliderTarget.value = value
  }

  updateStatusCircles(index) {
    // given a value 0-4

    // Only show relevant status text and highlight statusCircle
    const statusTexts = this.statusTextTargets
    const statusCircles = this.statusCircleTargets

    const selectedStatusText = statusTexts[index]
    const selectedStatusCircle = statusCircles[index]

    hide(statusTexts)
    deselect(statusCircles)
    show(selectedStatusText)
    select(selectedStatusCircle)

    const percent = this.completionSliderTarget.value
    const isNotStarted = selectedStatusText.dataset.value === statusTexts[0].dataset.value
    const isCompleted = selectedStatusText.dataset.value === statusTexts[statusTexts.length - 1].dataset.value

    if (isNotStarted) this.updateCompletionSlider(0)
    if (isCompleted) this.updateCompletionSlider(100)
    if (!isCompleted && percent === "100") this.updateCompletionSlider(0)
  }

  completionSlide(event) {
    const { value } = event.currentTarget

    this.updateCompletionSlider(value)
  }

  updateCompletionSlider(percent) {
    this.completionSliderTarget.value = percent
    this.completionSliderTarget.updateLabel && this.completionSliderTarget.updateLabel()

    if (percent === "100") {
      this.updateStatusSlider(4)
      this.updateStatusCircles(4)
    }
    if (percent < "100" && this.statusSliderTarget.value === "4") {
      this.updateStatusSlider(3)
      this.updateStatusCircles(3)
    }
  }
}