class DirtyForm {
  constructor(form, options = {}) {
    this.form = form
    this.isDirty = false
    this.initialValues = {}
    this.fields = [
      ...this.form.elements,
      ...this.form.querySelectorAll("trix-editor")
    ]
    this.message = options["message"] || "You have unsaved changes. Are you sure you want to continue"

    this.setupFields()
    this.setFormHandlers()
  }

  setupFields() {
    this.fields.forEach(field => {
      if (!field.name || field.type == "submit" || field.type == "button" || field.type == "hidden") {
        return
      }

      // Save initial values
      this.initialValues[field.name] = field.value

      // Set handlers
      if (field.nodeName == "TRIX-EDITOR") {
        field.addEventListener("trix-change", this.checkValue.bind(this))
      } else {
        field.addEventListener("change", this.checkValue.bind(this))
        field.addEventListener("input", this.checkValue.bind(this))
      }
    })
  }

  windowUnload() {
    if (this.isDirty) {
      return this.message
    }
  }

  cancelOrConfirm(event) {
    if (this.isDirty && !confirm(this.message)) {
      event.preventDefault()
    } else {
      this.isDirty = false
    }
  }

  setFormHandlers() {
    // Handle submit
    this.form.addEventListener("submit", this.handleSubmit.bind(this))

    // Handle leaving page
    window.onbeforeunload = this.windowUnload.bind(this)

    document.addEventListener("turbo:before-visit", this.cancelOrConfirm.bind(this))
  }

  checkValue(event) {
    let field = event.target

    if (this.initialValues[field.name] != field.value) {
      this.isDirty = true
    }
  }

  handleSubmit() {
    this.isDirty = false
  }

  tearDown() {
    this.isDirty = false
    this.initialValues = {}
  }
}

module.exports = DirtyForm