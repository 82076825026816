import { Controller } from "@hotwired/stimulus"
import CableReady from "cable_ready"

export default class extends Controller {
  static values = {
    url: String,
    bodyAsHtml: { type: Boolean, default: true }
  }

  generate(e) {
    e.preventDefault()
    let body = null
    let method = "GET"

    if (this.bodyAsHtmlValue) {
      const html = document.querySelector("html").outerHTML
      body = JSON.stringify({ html })
      method = "POST"
    }

    mrujs
      .fetch(this.urlValue, {
        method,
        headers: { "Accept": "text/vnd.cable-ready.json; charset=utf-8" },
        body
      })
      .then(resp => resp.json())
      .then(CableReady.perform)
  }
}