import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../lib/helpers"

export default class extends Controller {
  static targets = ["input", "suggestion", "option"]

  connect() {
    this.make()
  }

  make(event) {
    // suggestion click
    if (event && event.currentTarget.dataset.suggestionIdentifier) {
      event.preventDefault()
      const { suggestionIdentifier, suggestionName } = event.currentTarget.dataset

      this.showSuggestion(suggestionIdentifier)
      this.inputTarget.value = suggestionName

      return
    }

    // inputTarget's text matches one of the option names
    let option
    if (option = this.findMatchingOptionName(this.inputTarget.value)) {
      this.showSuggestion(option.dataset.suggestionIdentifier)

      return
    }

    // otherwise, hide all
    this.hideAllSuggestionTargets()
  }

  findMatchingOptionName(name) {
    return this.optionTargets.find(e => e.dataset.suggestionName.toLowerCase().trim() === name.toLowerCase().trim())
  }

  showSuggestion(suggestionIdentifier) {
    this.hideAllSuggestionTargets()
    show(
      this.suggestionTargets.find(e => e.dataset.suggestionIdentifier === suggestionIdentifier)
    )
  }

  hideAllSuggestionTargets() {
    hide(this.suggestionTargets)
  }
}