import { Controller } from "@hotwired/stimulus"
import { addClass, getUrlParam, scrollToAnchor } from "../lib/helpers"

const something = e => !!e
export default class extends Controller {
  connect() {
    this.addRequiredClassToLabels()
    scrollToAnchor()
    this.focusInput()
  }

  addRequiredClassToLabels() {
    Array
      .from(document.querySelectorAll("[required='required']"))
      .map(element => element.parentElement.querySelector("label.form-label") || element.parentElement.parentElement.querySelector("label.form-label"))
      .filter(something)
      .forEach(l => addClass(l, "required"))
  }

  focusInput() {
    document.getElementById(getUrlParam("focus"))?.focus()
  }
}
