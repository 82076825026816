import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = ["copySelector"]

  initialize() {
    this.change = this.change.bind(this)
  }

  connect() {
    this.element.addEventListener("sl-change", this.change)
  }

  disconnect() {
    this.element.removeEventListener("sl-change", this.change)
  }

  change(_event) {
    const v = this.element.value
    const selector = this.element.dataset.slCopyValueSelectValue

    document.querySelector(selector).value = v
  }
}