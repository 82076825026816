import { Controller } from "@hotwired/stimulus"
import { show } from "../lib/helpers"

const SEC_IN_MS = 1000
const MIN_IN_MS = 60 * SEC_IN_MS
const HR_IN_MS = 60 * MIN_IN_MS
const DAY_IN_MS = 24 * HR_IN_MS

export default class extends Controller {
  static values = { key: String }

  connect() {
    if (this.shouldHideElement) {
      this.removeSelf()
      return
    }

    this.showSelf()
  }

  removeSelf() {
    this.element.remove()
  }

  showSelf() {
    show(this.element)
  }

  get shouldHideElement() {
    const hideUntilValue = this.storage.getItem(this.keyValue)

    if (!hideUntilValue) {
      return false
    }
    if (!parseInt(hideUntilValue)) {
      return false
    }

    const parsedHideUntilValue = parseInt(hideUntilValue)
    if (Date.now() > parsedHideUntilValue) {
      return false
    }

    return true
  }

  get storage() {
    return window.localStorage
  }

  dismiss(event) {
    const msToHideUntil = (event.target.dataset.rememberedDismissedHideUntilDays || 30) * DAY_IN_MS

    this.storage.setItem(this.keyValue, Date.now() + msToHideUntil)
    this.removeSelf()
  }
}