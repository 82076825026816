import { Controller } from "@hotwired/stimulus"
import { isTime, iso8601Parse } from "../lib/date_helpers"
import { MetaVars } from "../lib/helpers"
const strftime = require('strftime')

export default class extends Controller {
  static values = {
    time: String
  }

  connect() {
    this.transform()
  }

  transform() {
    this.element.textContent = strftime(this.format, this.transformedTime)
  }

  get format() {
    if (isTime(this.timeValue)) return MetaVars.timeFormatString

    return MetaVars.dateFormatString
  }

  get transformedTime() {
    if (isTime(this.timeValue)) return new Date(this.timeValue)

    // Correct date timezone offset parsing bug.
    // Use the local timezone offset and add it to the existing date to ensure date isn't off by one day.
    return iso8601Parse(this.timeValue)
  }
}
