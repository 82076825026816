import { Controller } from "@hotwired/stimulus"

const cleanUrl = (url) => url.split("?").shift().split("#").shift()

export default class extends Controller {
  initialize() {
    this.onlyCurrentPageLink = this.onlyCurrentPageLink.bind(this)
    this.onlyValidMethodLink = this.onlyValidMethodLink.bind(this)
    this.addActiveLinkClass = this.addActiveLinkClass.bind(this)
  }

  connect() {
    this.addClassToActiveLinks()
  }

  addClassToActiveLinks() {
    Array
      .from(this.pageLinks)
      .filter(this.onlyCurrentPageLink)
      .filter(this.onlyValidMethodLink)
      .forEach(this.addActiveLinkClass)
  }

  get pageLinks() {
    return document.querySelectorAll("a[href]")
  }

  onlyValidMethodLink(link) {
    const { method } = link.dataset

    return [undefined, null, "get"].includes(method)
  }

  get currentPageHref() {
    if (!this._currentPageHref) {
      this._currentPageHref = cleanUrl(window.location.href)
    }

    return this._currentPageHref
  }

  onlyCurrentPageLink(link) {
    const hrefAttr = link.getAttribute("href")
    const { href } = link

    if (hrefAttr === "" || hrefAttr == "#" || !href) return // non-link equivalents

    return cleanUrl(href) === this.currentPageHref
  }

  addActiveLinkClass(link) {
    link.classList.add("active")
  }
}