import { BaseModal } from "./base_modal"

export default class extends BaseModal {
  showModal(event) {
    super.showModal(event)

    const { formUrl, cloningPlanTitle } = event.relatedTarget.dataset

    this.element.querySelector("form").setAttribute("action", formUrl)

    this.element.querySelector(".cloning-plan input").value = cloningPlanTitle
  }
}