import { Controller } from "@hotwired/stimulus"

import { MetaVars, show, hide } from "../lib/helpers"
import { StoredSetting } from "../lib/stored_setting"
import { triggerAction } from "../lib/helpers"

const CONDENSED_MODE_KEY = "condensed-mode"

export default class extends Controller {
  static targets = ["hideWhenCondensedOn"]

  connect() {
    this.setCondensedModeState()
    triggerAction()
  }

  setCondensedModeState() {
    StoredSetting.get(CONDENSED_MODE_KEY, MetaVars.organizationId, { default: false }) === true ? this.condensedModeOn() : this.condensedModeOff()
  }

  condensedModeOn() {
    hide(this.hideWhenCondensedOnTargets)

    StoredSetting.set(CONDENSED_MODE_KEY, MetaVars.organizationId, true)
    hide("#condensed-mode-on")
    show("#condensed-mode-off, .plan-alerts #condensed-mode-alert-on")
  }

  condensedModeOff() {
    show(this.hideWhenCondensedOnTargets)

    StoredSetting.set(CONDENSED_MODE_KEY, MetaVars.organizationId, false)
    hide("#condensed-mode-off, .plan-alerts #condensed-mode-alert-on")
    show("#condensed-mode-on")
  }

  get elementsWithFocus() {
    return document.querySelectorAll("[focus_for*='[']")
  }
}