import { Controller } from "@hotwired/stimulus"
import { addClass } from '../lib/helpers'

export default class extends Controller {
  static targets = ['success', 'image', 'deletionCheckBox']

  static values = {
    successContent: String
  }

  connect() {
    if (!this.deletionCheckBoxTarget) throw ("Doesn't have deletionCheckBox")
  }

  markToRemove(event) {
    event.preventDefault()

    this.showSuccess()
    this.setForRemoval()
    this.fadeImage()
  }

  showSuccess() {
    if (!this.hasSuccessTarget) return

    this.successTarget.innerHTML = this.successContentValue
  }

  setForRemoval() {
    this.deletionCheckBoxTarget.checked = true
  }

  fadeImage() {
    if (!this.hasImageTarget) return

    addClass(this.imageTarget, "opaque-20")
  }
}