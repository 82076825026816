
import CableReady from "cable_ready"
import mrujs from "mrujs"
import { CableCar, MrujsTurbo } from "mrujs/plugins"

mrujs.start({
  plugins: [
    new CableCar(CableReady, { mimeType: "text/vnd.cable-ready.json" }),
    MrujsTurbo()
  ]
})