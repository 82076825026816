import { Application } from "@hotwired/stimulus"
import Honeybadger from "@honeybadger-io/js"
import { MetaVars } from "../lib/helpers"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

window.Stimulus = Application.start()

// Setup Honeybadger
if (MetaVars.envProduction) {
  Honeybadger.configure({
    apiKey: 'f5cdd60c', // this is the client-side API key
    environment: MetaVars.honeybadgerEnv,
    revision: MetaVars.gitSha
  })

  application.handleError = (error, message, detail) => {
    console.error(message, detail)
    Honeybadger.notify(error)
  }

  document.addEventListener("turbo:load", function () {
    Honeybadger.setContext({
      user_id: MetaVars.userId,
      user_email: MetaVars.userEmail,
      user_name: MetaVars.userName,
      organization_id: MetaVars.organizationId,
      organization_name: MetaVars.organizationName,
    })
  })
} else {
  application.handleError = (error, message, detail) => {
    console.error("handleError", error, message, detail)
  }
}

const callStimulusTeardown = () => application.controllers.forEach(controller => controller.teardown && controller.teardown())
document.addEventListener("turbo:before-cache", callStimulusTeardown)

export { application }