import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static values = {
    seconds: Number
  }

  seek(event) {
    event.preventDefault()

    if (window.currentYoutubePlayer) {
      window.currentYoutubePlayer.seekTo(this.secondsValue)
    }
  }
}