export const isDate = (date) => !isTime(date)
export const isDateObject = (date) => date.toTimeString && typeof (date.toTimeString()) === "string"
export const isTime = (date) => date.includes("T")

export const iso8601Parse = (date) => {
  if (!date) { return }

  const timeInMs = (new Date(date)).getTime()
  const timeZoneOffsetInMilliseconds = new Date(date).getTimezoneOffset() * 60000

  return (new Date(timeInMs + timeZoneOffsetInMilliseconds))
}

export const addDays = (date, days) => {
  if (!date) { return }
  const baseDate = new Date(date)

  baseDate.setDate(baseDate.getDate() + days)

  return baseDate
}

export const subtractDays = (date, days) => addDays(date, -days)
export const dateNow = () => new Date()