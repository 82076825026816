import { BaseModal } from "./base_modal"

export default class extends BaseModal {
  showModal(event) {
    super.showModal(event)

    const { confirmationModalTitle, confirmationModalConfirmation, confirmationModalSuccessUrl, confirmationModalSuccessMethod, confirmationModalSuccessButton } = event.relatedTarget.dataset
    const form = this.element.querySelector("form")

    form.setAttribute("action", confirmationModalSuccessUrl)
    form.setAttribute("method", "post")
    form.querySelector("input[name='_method']").value = confirmationModalSuccessMethod
    this.element.querySelector(".modal-title").innerText = confirmationModalTitle
    this.element.querySelector(".modal-body p").innerText = confirmationModalConfirmation
    this.element.querySelector(".modal-footer input[type=submit]").value = confirmationModalSuccessButton
  }
}