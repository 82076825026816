import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../lib/helpers"

export default class extends Controller {
  static targets = ["to_show", "to_hide"]

  connect() {
    this.setInitialStates()
  }

  setInitialStates() {
    if (this.element.dataset.revealerState) {
      const initialStates = this.element.dataset.revealerState.split(" ").map(s => s.split(":"))

      initialStates.forEach(stateDetails => {
        const identifier = stateDetails[0]
        const state = stateDetails[1]

        if (state === "revealed") {
          this.reveal({ currentTarget: { dataset: { revealerIdentifier: identifier } } })
        } else {
          this.conceal({ currentTarget: { dataset: { revealerIdentifier: identifier } } })
        }
      })
    }
  }

  reveal(event) {
    this.setRevealerIdentifier(event)

    show(this.targetsToShow)
    hide(this.targetsToHide)
  }

  conceal(event) {
    this.setRevealerIdentifier(event)

    hide(this.targetsToShow)
    show(this.targetsToHide)
  }

  setRevealerIdentifier(event) {
    this.revealerIdentifier = event.currentTarget.dataset.revealerIdentifier
  }

  get targetsToShow() {
    if (!this.revealerIdentifier) return []

    return this.to_showTargets.filter(t => t.dataset.revealerIdentifier == this.revealerIdentifier)
  }

  get targetsToHide() {
    if (!this.revealerIdentifier) return []

    return this.to_hideTargets.filter(t => t.dataset.revealerIdentifier == this.revealerIdentifier)
  }
}
