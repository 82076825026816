import { Controller } from "@hotwired/stimulus"
import mrujs from "mrujs"
import { Flash } from "../lib/flash"

class PurchaseOrderForm extends Controller {
  get submitter() {
    return this.element.querySelector("input[type='submit']")
  }

  submitForm(e) {
    e.preventDefault()

    let form = this.element.closest("form")

    if (this.submitter.disabled) return
    this.submitter.loading = true
    this.submitter.disabled = true

    let formData = new FormData()
    Array
      .from(form.querySelectorAll("input[type='radio'], sl-switch"))
      .forEach(input => input.checked ? formData.append(input.name, input.value) : null)

    let url = form.action
    let type = form.method

    mrujs
      .fetch(url, {
        method: type,
        headers: { "Accept": "application/json" },
        body: mrujs.urlEncodeFormData(formData)
      })
      .then(resp => resp.json())
      .then(data => window.stripe.redirectToCheckout({ sessionId: data.stripe_session_id }))
      .catch(data => Flash.danger(data.error_messages))
  }
}

export default PurchaseOrderForm