import { BaseModal } from "./base_modal"
import { show, hide, addClass, removeClass, clearUrlParams, addUrlParams, triggerAction, loadHtmlContent } from "../lib/helpers"
import { log } from "../lib/log"

export default class extends BaseModal {
  static targets = [
    "statusCircle",
    "statusText",
    "statusSlider",
    "form",
    "metricName",
    "measurementNotes",
    "metricUnitIdentifier",
    "metricMeasurementSnapshotValue",
    "metricMeasurementDeltaValue",
    "metricMeasuredOn",
    "targetEndAt",
    "targetValue",
    "tabSnapshot",
    "tabDelta"
  ]

  afterModalSetup() {
    triggerAction()
  }

  showModal(event) {
    if (!this.metricMeasuredOnTarget || !this.metricMeasuredOnTarget._flatpickr) {
      log("retry...")
      this.showModalTimeout =
        setTimeout(
          () => this.showModal(event),
          100)

      return
    }

    super.showModal(event)

    const { dataset } = event.relatedTarget
    const { formUrl, formAction, metricName, metricEndAt, metricTargetValue, metricUnitIdentifier, metricValue, metricMeasuredOn, measurementNotes } = dataset

    this.formTarget.setAttribute("action", formUrl)
    this.formTarget.querySelector("input[name='_method']").value = formAction

    this.metricNameTarget.innerText = metricName
    this.targetEndAtTarget.innerText = metricEndAt || ""
    this.metricUnitIdentifierTargets.forEach(e => e.innerText = metricUnitIdentifier)
    this.targetValueTarget.innerText = metricTargetValue
    this.metricMeasurementSnapshotValueTarget.value = metricValue
    this.metricMeasurementDeltaValueTarget.value = 0

    if (metricMeasuredOn) {
      this.metricMeasuredOnTarget._flatpickr.setDate(metricMeasuredOn)
    }

    this.setupMeasurementModalStatusSlider(dataset)
    this.setupMeasurementEntryOptions(dataset)
    this.setUrlParams(dataset)

    if (this.hasMeasurementNotesTarget) {
      loadHtmlContent(this.measurementNotesTarget, measurementNotes)
    }
  }

  hideModal(event) {
    super.hideModal(event)

    if (this.showModalTimeout) {
      clearTimeout(this.showModalTimeout)
    }

    clearUrlParams()
  }

  showSnapshot() {
    this.metricMeasurementSnapshotValueTarget.disabled = false
    this.metricMeasurementDeltaValueTarget.disabled = true
  }

  showDelta() {
    this.metricMeasurementSnapshotValueTarget.disabled = true
    this.metricMeasurementDeltaValueTarget.disabled = false
  }

  selectStatusCircle(event) {
    const { index } = event.currentTarget.dataset

    this.updateStatusSlider(index)
    this.updateStatusCirclesAndText(index)
  }

  updateStatusSlider(val) {
    this.statusSliderTarget.value = val
  }

  updateStatusCirclesAndText(val) {
    // Only show relevant status text and highlight statusCircle
    hide(this.statusTextTargets)
    removeClass(this.statusCircleTargets, "selected")

    show(this.statusTextTargets[val])
    addClass(this.statusCircleTargets[val], "selected")
  }

  setupMeasurementModalStatusSlider(data) {
    const { metricStatusValue } = data

    this.updateStatusCirclesAndText(metricStatusValue)
    this.updateStatusSlider(metricStatusValue)
  }

  setupMeasurementEntryOptions(data) {
    const { metricMeasurementEntryOption } = data
    if (!metricMeasurementEntryOption) { return }

    switch (metricMeasurementEntryOption) {
      case "snapshot_preferred":
        show(this.tabSnapshotTarget)
        show(this.tabDeltaTarget)

        this.tabSnapshotTarget.click()
        break;
      case "delta_preferred":
        show(this.tabSnapshotTarget)
        show(this.tabDeltaTarget)

        this.tabDeltaTarget.click()
        break;
      case "snapshot_only":
        show(this.tabSnapshotTarget)
        hide(this.tabDeltaTarget)

        this.tabSnapshotTarget.click()
        hide(this.tabSnapshotTarget)
        break;
      case "delta_only":
        hide(this.tabSnapshotTarget)
        show(this.tabDeltaTarget)

        this.tabDeltaTarget.click()
        hide(this.tabDeltaTarget)
        break;
    }
  }

  statusSliderChange(event) {
    const { value } = event.target

    this.updateStatusCirclesAndText(value)
  }

  setUrlParams(data) {
    const { metricId } = data

    addUrlParams({
      trigger_action: "click",
      trigger_selector: `[data-metric-id='${metricId}'] .status-circle`
    })
  }
}