
import { Controller } from "@hotwired/stimulus"
import mrujs from "mrujs"
import throttle from "lodash/throttle"
import { createFragment } from "../lib/helpers"

const fetchAndReplace = (url, formData, element) => {
  mrujs
    .fetch(url, {
      method: "GET",
      body: mrujs.urlEncodeFormData(formData)
    })
    .then(resp => resp.text())
    .then(html => {
      const fragment = createFragment(html)
      const fragmentRoot = fragment.querySelector("[data-controller*='remotely-update']")

      element.outerHTML = fragmentRoot.outerHTML
    })
}

const throttledFetchAndReplace = throttle(fetchAndReplace, 250, { trailing: false })

export default class extends Controller {
  static targets = ["field"]
  static values = {
    url: String
  }

  connect() {
    this._element = this.element
  }

  now(_event) {
    let formData = new FormData()

    this.fieldTargets.forEach(field => {
      let value = field.value

      if (field.type == "checkbox") {
        value = field.checked
      }

      formData.append(field.name, value)
    })

    throttledFetchAndReplace(this.urlValue, formData, this.element)
  }
}