import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "label"]
  static values = {
    format: String
  }

  connect() {
    this.setupUpdateLabelHandle()
    this.updateLabel()
  }

  setupUpdateLabelHandle() {
    if (!this.hasInputTarget) return

    this.inputTarget.updateLabel = this.updateLabel.bind(this)
  }

  updateLabel() {
    if (this.hasInputTarget && this.hasLabelTarget) {
      const input = this.inputTarget
      const label = this.labelTarget

      // Calculate values
      const val = input.value
      const min = input.min || 0
      const max = input.max || 100
      const newVal = Number(((val - min) * 100) / (max - min))

      // Set label text
      label.innerHTML = this.formattedValue(val)

      // Set label positioning
      // Magic numbers based on size of the native UI thumb
      label.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
    }
  }

  // private
  formattedValue(val) {
    if (!this.hasFormatValue) return val

    return this.formatValue.replace(":value:", val.toString())
  }
}