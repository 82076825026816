import { log } from "../lib/log"
import { createFragment } from "./helpers"

export class Flash {
  static success(content) {
    this.add(content, "success")
  }

  static info(content) {
    this.add(content, "info")
  }

  static danger(content) {
    this.add(content, "danger")
  }

  static error(content) {
    this.add(content, "danger")
  }

  static alert(content) {
    this.add(content, "danger")
  }

  static get flash() {
    return document.getElementById("flash")
  }

  static add(content, type) {
    log(`Adding Flash: [${type}] ${content}`)
    const newFlash = createFragment(this.template(type, content))
    this.flash.appendChild(newFlash)
  }

  static template(type, content) {
    return `<div class="alert alert-dismissible alert-${type} fade show" role="alert" data-controller='alert'>${content}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>`
  }
}