import { Controller } from "@hotwired/stimulus"
import throttle from "lodash/throttle"
import Fuse from 'fuse.js'
import { hideElements, showElements, showElement } from "../lib/helpers"


export default class extends Controller {
  static targets = ["search", "item"]

  initialize() {
    this.update = throttle(this.update, 100)
  }

  update() {
    if (!this.hasSearchTarget) { return }

    const results = this.searchResults()

    if (results.length === 0) { showElements(this.itemTargets); return }
    if (!this.searchText) { return }

    // hide all elements
    hideElements(this.itemTargets)

    // show matched elements
    results.map(i => i.item.element).forEach(showElement)
  }

  get searchText() {
    return this.searchTarget.value.trim()
  }

  searchResults() {
    const list =
      this.itemTargets
        .map(element => {
          return {
            element,
            text: element.innerText
          }
        })

    const fuse = new Fuse(list, { keys: ["text"], distance: 80, threshold: 0.4 })

    return fuse.search(this.searchText)
  }

  clear() {
    if (!this.hasSearchTarget) { return }

    this.searchTarget.value = ""
    this.update()
  }
}