import { Controller } from "@hotwired/stimulus"
import { MetaVars, show, hide } from "../lib/helpers"
import { log } from "../lib/log"

export default class extends Controller {
  static targets = [
    "sessionLostMessage"
  ]

  static values = {
    sessionInfoUrl: String
  }

  initialize() {
    this.notifySession = this.notifySession.bind(this)
  }

  gain() {
    this.checkForActiveSession()
  }

  checkForActiveSession() {
    if (!this.sessionInfoUrlValue) return

    fetch(this.sessionInfoUrlValue)
      .then(response => response.json())
      .then(dataObject => dataObject?.session_id)
      .then(this.notifySession)
  }

  notifySession(sessionId) {
    if (MetaVars.sessionId === "" || MetaVars.sessionId === sessionId) {
      log("Session Active")
      hide(this.sessionLostMessageTargets)
    } else {
      log("Session Inactive")
      show(this.sessionLostMessageTargets)
    }
  }
}