import { Controller } from "@hotwired/stimulus"
import { hide, show, getUrlAnchor, scrollToAnchor } from "../lib/helpers"
import { log } from "../lib/log"
import { StoredSetting } from "../lib/stored_setting"

const EXPAND = "expand"
const COLLAPSE = "collapse"
const EXPANDED_OR_COLLAPSED_KEY = "expanded-or-collapsed-items"
const CONTROL_DEFAULTS_KEY = "expanded-or-collapsed-control-default-items"

const eagerLoadChildTurboFrames = (parentElement) => {
  parentElement
    .querySelectorAll("turbo-frame[loading='lazy']")
    .forEach(tf => {
      if (tf.closest("[data-controller~='expand-or-collapse']") == parentElement) {
        tf.setAttribute("loading", "eager")
      }
    })
}

export default class extends Controller {
  static targets = ["hideWhenExpanded", "hideWhenCollapsed"]
  static values = {
    identifier: String,
    controlDefault: String,
    eagerLoadWait: {
      type: Number,
      default: 150
    }
  }

  disconnect() {
    log("expandOrCollapse: disconnect")
  }

  connect() {
    log("expandOrCollapse: connect")

    if (!this.hasHideWhenExpandedTarget || !this.hasHideWhenCollapsedTarget) {
      log("Expand or Collapse Controller: Should have at least one hideWhenExpandedTargets and one hideWhenCollapsedTargets", this.identifierValue)
    }

    // If default changed, reset the setting
    if (this.rememberedControlDefault !== this.controlDefaultValue) {
      StoredSetting.remove(CONTROL_DEFAULTS_KEY, this.identifierValue)
      StoredSetting.remove(EXPANDED_OR_COLLAPSED_KEY, this.identifierValue)
    }

    // Persist default setting
    StoredSetting.set(CONTROL_DEFAULTS_KEY, this.identifierValue, this.controlDefaultValue)

    // Expand if child element has anchor tag pointing to it
    const anchor = getUrlAnchor()
    if (anchor && this.element.querySelector(anchor)) {
      StoredSetting.set(EXPANDED_OR_COLLAPSED_KEY, this.identifierValue, EXPAND)
      this.expand()
      scrollToAnchor()
    }

    // When remembered as expanded
    if (this.isRememberedAsExpanded) {
      this.expand()
      return
    }

    // When remembered as collapsed
    if (this.isRememberedAsCollapsed) {
      this.collapse()
      return
    }

    // When relying on defaults
    if (this.isExpandingControlDefault) {
      this.expand()
    } else {
      this.collapse()
    }
  }

  get isExpandingControlDefault() {
    return !this.isCollapsingControlDefault
  }

  get isRememberedAsExpanded() {
    return StoredSetting.get(EXPANDED_OR_COLLAPSED_KEY, this.identifierValue) === EXPAND
  }

  get isCollapsingControlDefault() {
    return this.controlDefaultValue === COLLAPSE
  }

  get isRememberedAsCollapsed() {
    return StoredSetting.get(EXPANDED_OR_COLLAPSED_KEY, this.identifierValue) === COLLAPSE
  }

  get rememberedControlDefault() {
    return StoredSetting.get(CONTROL_DEFAULTS_KEY, this.identifierValue)
  }

  expand(event) {
    event?.stopImmediatePropagation()
    event?.preventDefault()

    eagerLoadChildTurboFrames(this.element)

    hide(this.hideWhenExpandedTargets)
    show(this.hideWhenCollapsedTargets)

    this.remember(EXPAND)
  }

  collapse(event) {
    event?.stopImmediatePropagation()
    event?.preventDefault()

    hide(this.hideWhenCollapsedTargets)
    show(this.hideWhenExpandedTargets)

    this.remember(COLLAPSE)
  }

  loadChildTurboFramesStart(event) {
    this.eagerLoadingTimer =
      setTimeout(
        () => {
          eagerLoadChildTurboFrames(this.element)
          this.eagerLoadingTimer = null
        },
        this.eagerLoadWaitValue
      )
  }

  loadChildTurboFramesEnd(event) {
    if (this.eagerLoadingTimer) {
      clearTimeout(this.eagerLoadingTimer)
      this.eagerLoadingTimer = null
    }
  }

  remember(value) {
    StoredSetting.set(EXPANDED_OR_COLLAPSED_KEY, this.identifierValue, value)
  }
}