import { Controller } from "@hotwired/stimulus"
import browserUpdate from 'browser-update'

const HOURS_PER_DAY = 24

export default class extends Controller {
  connect() {
    this.renderBrowserUpdate()
  }

  renderBrowserUpdate() {
    browserUpdate(
      {
        required: {
          c: -3, // chrome
          e: -1, // edge
          f: -3, // firefox
          i: null, // internet explorer
          o: -3, // opera
          s: -1, // safari
        },
        insecure: true,
        unsupported: true,
        reminder: HOURS_PER_DAY, // after how many hours should the message reappear
        reminderClosed: HOURS_PER_DAY * 14, // if the user explicitly closes message it reappears after X hours
        url: "https://help.causey.app/article/95-system-requirements"
      }
    )
  }
}