import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../lib/helpers"

export default class extends Controller {
  static targets = ["unit", "unitOther", "metricUnitOther"]

  connect() {
    this.setVisibility()
  }

  setVisibility() {
    if (this.unitTarget.value === "other") {
      show(this.unitOtherTarget)
      this.metricUnitOtherTarget.required = true
      return
    }

    hide(this.unitOtherTarget)
    this.metricUnitOtherTarget.required = false
  }
}
