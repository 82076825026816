import { Controller } from "@hotwired/stimulus"
import { MetaVars } from "../lib/helpers"
import posthog from "posthog-js"

export default class extends Controller {
  connect() {
    if (this.disabled) return

    posthog.init(MetaVars.posthogApiKey, {
      api_host: "https://app.posthog.com",
      loaded: this.identify.bind(this)
    })
  }

  get disabled() {
    return !MetaVars.posthogEnabled
  }

  get optOut() {
    return !!MetaVars.userSuperAdmin
  }

  reset() {
    if (!posthog.__loaded) return

    posthog.reset()
  }

  identify() {
    const userId = MetaVars.userId?.toString()

    // Reset session if userId isn't present or stored userId is
    // not the same as the current userId
    if (!userId || this._userId !== userId) {
      this.reset()
    }

    this._userId = userId

    // if (this.optOut) {
    //   posthog.opt_out_capturing()
    //   return
    // }

    posthog.identify(
      userId, {
      'email': MetaVars.userEmail,
      'userName': MetaVars.userName,
      'userSignedUpAt': MetaVars.userSignedUpDate,
      'lastSystemController': MetaVars.controller,
      'lastSystemAction': MetaVars.action,
      'lastMembershipType': MetaVars.membershipType,
      'lastMembershipPermissionLevel': MetaVars.membershipRole
    })
  }
}