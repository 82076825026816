import { Controller } from "@hotwired/stimulus"

import Chart from "chart.js/auto"
import "chartjs-adapter-date-fns"
import { coerce } from "../lib/helpers"

const numericallySort = (a, b) => a - b
const extractYs = data => data.y

export default class extends Controller {
  static values = {
    data: Object,
    type: String,
    fileName: String,
    backgroundColor: String,
    xAxisUnit: String,
    xAxisMinimumDate: String,
    xAxisMaximumDate: String
  }

  static targets = ["canvas"]

  connect() {
    this.setup()
  }

  setup() {
    this._chart =
      new Chart(this.canvasTarget, {
        type: this.typeValue || "line",
        data: this.dataValue,
        plugins: [
          this.backgroundPlugin()
        ],
        options: {
          responsive: true,
          scales: {
            y: this.yScale,
            x: this.xScale
          }
        }
      })
  }

  disconnect() {
    if (this._chart) {
      this._chart.destroy()
      this._chart = null
    }
  }


  // actions
  exportAsPNG(e) {
    e.preventDefault()

    if (!this._chart) return

    let link = document.createElement("a")
    link.download = this.fileNameValue || "chart"

    this._chart.resize(1600, 900)
    link.href = this.canvasTarget.toDataURL("image/png")

    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    this._chart.resize()
  }

  // private
  backgroundPlugin() {
    return ({
      id: "custom_canvas_background_color",
      beforeDraw: (chart) => {
        const ctx = chart.canvas.getContext("2d")
        ctx.save()
        ctx.globalCompositeOperation = "destination-over"
        ctx.fillStyle = this.backgroundColorValue || "white"
        ctx.fillRect(0, 0, chart.width, chart.height)
        ctx.restore()
      }
    })
  }

  get minValue() {
    return this.allValues.shift();
  }

  get maxValue() {
    return this.allValues.pop();
  }

  get allValues() {
    const data = this.dataValue.datasets.map(dataset => dataset.data)
    return data.flatMap(data => data.map(extractYs)).map(coerce).sort(numericallySort)
  }

  get yScale() {
    const suggestedMin = this.minValue > 0 ? 0 : Math.floor(this.minValue * 1.01)
    const suggestedMax = this.maxValue < 0 ? 0 : Math.ceil(this.maxValue * 1.01)

    return (
      {
        suggestedMin: suggestedMin,
        suggestedMax: suggestedMax
      }
    )
  }

  get xScale() {
    return (
      {
        type: 'time',
        time: {
          unit: this.xAxisUnitValue || 'month',
        },
        min: this.xAxisMinimumDateValue,
        max: this.xAxisMaximumDateValue
      }
    )
  }
}