import { BaseModal } from "./base_modal"

export default class extends BaseModal {

  showModal(event) {
    super.showModal(event)

    const { typedConfirmationModalTitle, typedConfirmationModalTypedConfirmation, typedConfirmationModalMatchingText, typedConfirmationModalSuccessUrl, typedConfirmationModalSuccessMethod, typedConfirmationModalSuccessButton } = event.relatedTarget.dataset

    const form = this.element.querySelector("form")

    form.setAttribute("action", typedConfirmationModalSuccessUrl)
    form.setAttribute("method", "post")

    form.querySelector("input[name='_method']").setAttribute("value", typedConfirmationModalSuccessMethod)
    this.element.querySelector(".modal-title").innerText = typedConfirmationModalTitle
    this.element.querySelector(".modal-body p").innerText = typedConfirmationModalTypedConfirmation

    this.submitButton.value = typedConfirmationModalSuccessButton
    this.submitButton.setAttribute("disabled", true)

    this.textInput.value = ""
    this.textInput.dataset.matchingText = typedConfirmationModalMatchingText
  }

  get textInput() {
    return this.element.querySelector(".modal-body input")
  }

  get submitButton() {
    return this.element.querySelector(".modal-footer input[type=submit]")
  }

  confirmationCompare() {
    const inputText = this.textInput.value.toLowerCase().trim()
    const matchingText = this.textInput.dataset.matchingText.toLowerCase().trim()
    const enabled = (inputText === matchingText) ? true : false

    enabled ? this.submitButton.removeAttribute("disabled") : this.submitButton.setAttribute("disabled", "true")
  }

}