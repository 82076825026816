import { BaseModal } from "./base_modal"

export default class extends BaseModal {
  initialize() {
    this._originalMarkup = this.element.innerHTML
  }

  hideModal() {
    super.hideModal()
    this._resetMarkup()
  }

  _resetMarkup() {
    this.element.innerHTML = this._originalMarkup
  }
}