import { Controller } from "@hotwired/stimulus"
import { toggleClass } from "../lib/helpers"

export default class extends Controller {
  static targets = ["content"]

  toggle(e) {
    e.preventDefault()
    toggleClass(this.contentTargets, "d-none")
  }
}