import { Controller } from "@hotwired/stimulus"
import { DataSet } from "vis-data"
import { Timeline } from "vis-timeline/esnext"
import "vis-timeline/styles/vis-timeline-graph2d.css"
import { dateNow, iso8601Parse, addDays, subtractDays } from "../lib/date_helpers"
import { userOnMobile, hide, show } from "../lib/helpers"
import { log } from "../lib/log"
const DAY_IN_MS = 1000 * 60 * 60 * 24
const MONTH_IN_MS = DAY_IN_MS * 30
const DAYS_IN_YEAR = 365

export default class extends Controller {
  static targets = ["group", "item", "container", "loading", "extraLoading", "noItems", "noItemsGroup", "controlsContainer"]

  connect() {
    const { timelineStartDate, timelineEndDate } = this.element.dataset

    this.shadedStartDate = new Date(timelineStartDate)
    this.shadedEndDate = new Date(timelineEndDate)
    this.containerStartDate = subtractDays(this.earliestDate, DAYS_IN_YEAR * 2)
    this.containerEndDate = addDays(this.latestDate, DAYS_IN_YEAR * 2)
    this.setupTimeline()
  }

  get earliestDate() {
    return this
      .timelineItems
      .map(i => i.start)
      .concat([this.shadedStartDate])
      .sort()
      .shift()
  }

  get latestDate() {
    return this
      .timelineItems
      .map(i => i.end || i.start)
      .concat([this.shadedEndDate])
      .sort()
      .pop()
  }

  destroy() {
    if (this._timeline) {
      // the timeline might have already been destroyed
      // if so, there doesn't appear to be a way to check
      // try it first and then deallocate.
      // See https://github.com/almende/vis/issues/3153
      try {
        this._timeline.destroy()
      }
      catch { }
      finally {
        this._timeline = null
      }
    }
  }

  disconnect() {
    this.destroy()
    if (this.hasControlsContainerTarget) { show(this.controlsContainerTarget) }
  }

  setupTimeline() {
    this._timeline =
      new Timeline(
        this.containerTarget,
        this.items,
        this.groups,
        this.timelineOptions
      )

    this.showNoItemsScreen()
  }

  get timelineItems() {
    return this
      .itemTargets
      .map(element => {
        const { id } = element
        const { dataset } = element
        let { timelineStart, timelineEnd, timelineGroup, timelineStyle, timelineType } = dataset

        timelineStart = iso8601Parse(timelineStart)
        timelineEnd = iso8601Parse(timelineEnd)

        return {
          id,
          content: element,
          group: timelineGroup,
          start: timelineStart,
          end: timelineEnd,
          style: timelineStyle,
          type: timelineType
        }
      })
      .filter(item => item.start) // only include items with a start date
  }

  get backgroundItems() {
    return [
      {
        id: "vis-background-shaded-item",
        start: this.shadedStartDate,
        end: this.shadedEndDate,
        type: "background",
        className: "shaded"
      }
    ]
  }

  get items() {
    if (!this._items) {
      this._items = new DataSet(
        this.timelineItems.concat(
          this.backgroundItems
        )
      )
    }

    return this._items
  }

  get groups() {
    if (userOnMobile()) { return } // Don't show Focus Areas on mobile
    if (!this._groups) {
      this._groups = new DataSet(
        this.groupTargets.map(element => {
          const { id } = element
          const { timelineStyle } = element.dataset

          // infer who the children group ids based on which groups are their parents
          const childrenGroupIds = this.groupTargets.filter(e => e.dataset.timelineGroupParentId == id).map(e => e.id)

          return {
            id,
            content: element,
            style: timelineStyle,
            nestedGroups: childrenGroupIds.length ? childrenGroupIds : null
          }
        })
      )
    }

    return this._groups
  }

  get timeline() {
    return this._timeline
  }

  timelineZoomAdjust(event) {
    event.preventDefault()
    const days = event.srcElement.value

    const start = this.timeline.getWindow().start
    const end = new Date(start.getTime() + (days * DAY_IN_MS))

    this.timeline.setWindow(null, end)
  }

  timelineZoomIn(event) {
    event.preventDefault()
    this.timeline.zoomIn(0.5)
  }

  timelineZoomOut(event) {
    event.preventDefault()
    this.timeline.zoomOut(0.5)
  }

  timelineMoveLeft(event) {
    event.preventDefault()
    this.timelineMove(1)
  }

  timelineMoveRight(event) {
    event.preventDefault()
    this.timelineMove(-1)
  }

  timelineFocusToday(event) {
    event.preventDefault()
    this.timeline.moveTo(dateNow())
  }

  timelineMove(percentage) {
    var range = this.timeline.getWindow()
    var interval = range.end - range.start

    this.timeline.setWindow({
      start: range.start.valueOf() - interval * percentage,
      end: range.end.valueOf() - interval * percentage
    })
  }

  get timelineOptions() {
    return {
      orientation: {
        axis: "top",
        item: "top"
      },
      margin: {
        item: 20
      },
      start: subtractDays(dateNow(), 30 * 3),
      end: addDays(dateNow(), 30 * 3),
      max: this.containerEndDate,
      min: this.containerStartDate,
      horizontalScroll: true,
      verticalScroll: true,
      zoomKey: 'ctrlKey',
      zoomMax: MONTH_IN_MS * 12 * 5,
      zoomMin: MONTH_IN_MS / 2,
      loadingScreenTemplate: this.loadingScreenLookup.bind(this),
      onInitialDrawComplete: () => { log("initial draw complete") }
    }
  }

  loadingScreenLookup() {
    if (this.noItems) return this.noItemsTarget
    if (this.hasLoadingTarget) return this.loadingTarget
  }

  get noItems() {
    return this.timelineItems.length === 0
  }

  showNoItemsScreen() {
    if (this.noItems) {
      if (this.timeline) this.timeline.destroy()
      if (this.hasControlsContainerTarget) hide(this.controlsContainerTarget)
    }
  }

  tearDownAndShowLoading() {
    this.destroy()
    this.showExtraLoading()
  }

  showExtraLoading() {
    this.containerTarget.appendChild(this.extraLoadingTarget)
  }
}