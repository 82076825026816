import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['button']
  static values = {
    copyText: String,
    successContent: String,
    successDuration: {
      type: Number,
      default: 2000
    }
  }

  connect() {
    if (!this.hasButtonTarget) return

    this.originalContent = this.buttonTarget.innerHTML
  }

  copy(event) {
    event.preventDefault()

    // Set the copyTextValue to an input that is way off screen
    const input = document.createElement("input")
    input.value = this.copyTextValue
    input.style.position = "absolute"
    input.style.left = "-1000px"
    input.id = "copy"
    document.body.appendChild(input)

    // Select text and copy
    input.select()
    document.execCommand('copy')

    // Remove element and re-focus
    input.remove()
    event.target.focus()

    // Copied callback
    this.copied()
  }

  copied() {
    if (!this.hasButtonTarget) return

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.buttonTarget.innerHTML = this.successContentValue

    this.timeout = setTimeout(() => {
      this.buttonTarget.innerHTML = this.originalContent
    }, this.successDurationValue)
  }
}