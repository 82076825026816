
import { eventLog, eventError } from "../lib/log"
import Honeybadger from "@honeybadger-io/js"

eventLog("turbo:click")
eventLog("turbo:before-visit")
eventLog("turbo:visit")
eventLog("turbo:request-start")
eventLog("turbo:request-end")
eventLog("turbo:before-cache")
eventLog("turbo:before-render")
eventLog("turbo:render")
eventLog("turbo:load")
eventError("turbo:frame-missing")

document.addEventListener("turbo:frame-missing", event => {
  Honeybadger.notify({
    message: "Turbo frame missing",
    name: "turbo:frame-missing",
    context: {
      "targetId": event.target.id,
      "targetSource": event.target.src,
      "responseUrl": event.detail.response.url,
      "responseRedirected": event.detail.response.redirected,
      "responseStatus": event.detail.response.status,
      "targetOuterText": event.target.outerText,
      "targetOuterHTML": event.target.outerHTML
    }
  })
})