import { BaseModal } from "./base_modal"
import { show, hide } from "../lib/helpers"

export default class extends BaseModal {
  static targets = ["progressBar"]

  showModal() {
    super.showModal(event)

    this.randomly_swap(
      Array.from(this.itemsToHide),
      Array.from(this.itemsToShow)
    )
  }

  hideModal(event) {
    super.hideModal(event)

    show(this.itemsToHide)
    hide(this.itemsToShow)
  }

  get itemsToHide() {
    return this.element.querySelectorAll(".hide-verified")
  }

  get itemsToShow() {
    return this.element.querySelectorAll(".show-verified")
  }

  randomly_swap(itemsToHide, itemsToShow) {
    const delay_minimum = 150
    const delay_range = 1000
    const delay_ms = Math.floor(delay_minimum + (Math.random(1) * delay_range))

    setTimeout((() => {
      const toShow = itemsToShow.shift()
      const toHide = itemsToHide.shift()

      if (toShow && toHide) {
        hide(toHide)
        show(toShow)

        this.randomly_swap(itemsToHide, itemsToShow)
      }
    }
    ), delay_ms)
  }

}