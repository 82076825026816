import { Controller } from "@hotwired/stimulus"
import CableReady from "cable_ready"
import mrujs from "mrujs"

export default class extends Controller {
  static values = {
    url: String
  }

  submit(method, event) {
    let formData = new FormData()
    // TODO: Once Stimulus v3 is released, use `params`
    // as discussed in https://github.com/hotwired/stimulus/issues/64
    const { name, value } = event.currentTarget
    const url = this.urlValue

    if (name && !url.includes(`${name}=`)) { formData.append(name, value) }
    if (value) { formData.append("value", value) }

    mrujs
      .fetch(url, {
        method,
        body: mrujs.urlEncodeFormData(formData)
      })
      .then(resp => resp.json())
      .then(CableReady.perform)
  }

  get(event) {
    this.submit("get", event)
  }

  post(event) {
    this.submit("post", event)
  }

  put(event) {
    this.submit("put", event)
  }

  delete(event) {
    this.submit("delete", event)
  }
}