import { Controller } from "@hotwired/stimulus"
import { toggleClass } from "../lib/helpers"

export default class extends Controller {
  toggle(event) {
    event.preventDefault()
    const targetIdentifiers = event.currentTarget.dataset.toggleTarget.split(",")
    const toggleHiddenClass = target => toggleClass(target, this.hiddenClass)

    targetIdentifiers.forEach((identifier) =>
      this
        .element
        .querySelectorAll(`[data-toggle-identifier~="${identifier}"]`)
        .forEach(toggleHiddenClass)
    )
  }

  get hiddenClass() {
    return this.element.dataset.hiddenClass || "d-none";
  }
}