import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import { settingDefaultFalse, settingDefaultTrue } from '../lib/helpers'

export default class extends Controller {
  static values = {
    delay: {
      type: Number,
      default: 500
    },
    zIndex: {
      type: Number,
      default: 10
    },
    animation: {
      type: String,
      default: "fade"
    },
    strategy: {
      type: String,
      default: "absolute"
    },
    followCursor: {
      type: String,
      default: "horizontal"
    },
    interactive: {
      type: Boolean,
      default: true
    },
    placement: {
      type: String,
      default: "top"
    },
    maxWidth: {
      type: String,
      default: "none"
    },
    shouldFollowCursor: {
      type: Boolean,
      default: false
    },
    url: String
  }

  initialize() {
    this.showHovercard = this.showHovercard.bind(this)
  }

  connect() {
    this._tippy = tippy(this.element, {
      content: this.loadingTextValue,
      allowHTML: true,
      interactive: this.interactiveValue,
      animation: this.animationValue,
      zIndex: this.zIndexValue,
      appendTo: document.body,
      placement: this.placementValue,
      maxWidth: this.maxWidthValue,
      delay: this.delayValue,
      popperOptions: {
        strategy: this.strategyValue
      },
      ...this.followCursorSetting,
      plugins: this.plugins,
      onShow: this.showHovercard
    })
  }

  showHovercard(instance) {
    fetch(this.urlValue)
      .then(r => r.text())
      .then(html => instance.setProps({
        content: html
      }))
  }

  get plugins() {
    let pluginsContainer = []

    if (this.shouldFollowCursorValue) {
      const { followCursor } = require("tippy.js")
      pluginsContainer.push(followCursor)
    }

    return pluginsContainer
  }

  get followCursorSetting() {
    return this.shouldFollowCursor ? { followCursor: true } : {}
  }

  disconnect() {
    if (this._tippy) {
      this._tippy.destroy()
    }
  }
}