import { Controller } from "@hotwired/stimulus"
import { log } from "../lib/log"
import SlimSelect from "slim-select"
import { hide } from "../lib/helpers"
import "../../../node_modules/slim-select/dist/slimselect.css"
import "../../assets/stylesheets/slimselect-customized.scss"

export default class extends Controller {
  static values = {
    hideOptions: Boolean,
    hideSearch: Boolean,
    matchingController: String,
    limit: Number,
    placeholder: String,
    searchPlaceholder: String,
    noResults: String,
    addable: Boolean
  }

  connect() {
    this.preprocessPlaceholder()
    this.element["slimSelect"] = this.slimSelect

    if (this.addableValue) this.setupAddOnEnter()

    if (this.hideOptionsValue) {
      hide(this.ssContents)
      hide(this.ssMain.querySelectorAll(".ss-arrow, .ss-add"))
    }
  }

  // events
  beforeOpen(event) {
    log("beforeOpen")
    this._surfaceEvent("slimselect:before-open")
  }

  afterOpen(event) {
    log("afterOpen")
    // if (this.autoCloseValue) this.slimSelect.close()

    this._surfaceEvent("slimselect:after-open")
  }

  error(event) {
    log(event)
  }

  beforeClose(event) {
    log("beforeClose", event)
    this._surfaceEvent("slimselect:before-close")
  }

  afterClose(event) {
    log("afterClose", event)
    this._surfaceEvent("slimselect:after-close")
  }

  beforeChange(event) {
    log("beforeChange", event)
    this._surfaceEvent("slimselect:before-change", event)
    return true
  }

  afterChange(event) {
    log("afterClose", event)
    this._surfaceEvent("slimselect:after-change")
  }

  _surfaceEvent(eventName) {
    const event = new CustomEvent(eventName, {
      detail: {
        relatedTarget: this.ssMain,
        matchingController: this.matchingControllerValue
      },
      bubbles: true
    })

    this.element.dispatchEvent(event)
  }

  // properties
  get ssMain() {
    return this.element.parentElement.querySelector(`.ss-main[data-id='${this.ssId}']`)
  }

  get ssContents() {
    return document.querySelector(`.ss-content[data-id='${this.ssId}']`)
  }

  get ssId() {
    return this.slimSelect.settings.id
  }

  get single() {
    return !this.element.multiple
  }

  get multi() {
    return !!this.element.multiple
  }

  get slimSelect() {
    if (this._slimSelect) return this._slimSelect

    const closeOnSelect = this.single
    const allowDeselect = !this.element.required
    const showSearch = !this.hideSearchValue
    const addable = this.addableValue ? this.addableHandler.bind(this) : null

    this._slimSelect =
      new SlimSelect({
        select: this.element,
        settings: {
          allowDeselect,
          closeOnSelect,
          maxSelected: this.limitValue,
          searchPlaceholder: this.searchPlaceholderValue || this.placeholderValue,
          placeholderText: this.placeholderValue,
          searchText: this.noResultsValue,
          showSearch,
        },
        events: {
          beforeOpen: this.beforeOpen.bind(this),
          afterOpen: this.afterOpen.bind(this),
          beforeClose: this.beforeClose.bind(this),
          afterClose: this.afterClose.bind(this),
          beforeChange: this.beforeChange.bind(this),
          afterChange: this.afterChange.bind(this),
          addable,
          error: log
        }
      })

    return this._slimSelect
  }

  // Find the 'blank' option and add the data-placeholder="true" attribute
  // so that slimselect properly renders the placeholder
  preprocessPlaceholder() {
    const possiblePlaceholderOption = this.element.querySelector("option")

    if (possiblePlaceholderOption && possiblePlaceholderOption.value == "" && possiblePlaceholderOption.innerText == "") {
      possiblePlaceholderOption.dataset.placeholder = true
    }
  }

  setupAddOnEnter() {
    let contentSearch = this.slimSelect.render.content.search
    contentSearch.input.addEventListener("keyup", (event) => {
      if (this.slimSelect.events.addable && event.key === "Enter") {
        contentSearch.addable.main.click()
        contentSearch.input.value = ""
      }
    })
  }

  addableHandler(value) {
    value = value.trim()

    // if we are case mis-matched from a preexising option
    // return the existing option's value
    const data = this.slimSelect.render.store.data
    const foundData = data.find(e => e.value.toLowerCase() == value.toLowerCase())
    if (foundData) return foundData.value

    return value
  }

  disconnect() {
    if (this.slimSelect) {
      this.element["slimSelect"] = null
      this.slimSelect.destroy()
      this._slimSelect = null
    }
  }
}