import { Controller } from "@hotwired/stimulus"
import { delay, fadeOutElement } from "../lib/helpers"

export default class extends Controller {
  static values = {
    delay: {
      type: Number,
      default: 3000
    },
    autoDismiss: {
      type: Boolean,
      default: true
    }
  }

  initialize() {
    this.triggerHide = this.triggerHide.bind(this)
  }

  connect() {
    this.dismiss()
  }

  dismiss() {
    if (!this.autoDismissValue) return

    delay(this.delayValue)
      .then(this.triggerHide)
  }

  teardown() {
    this.element.remove()
  }

  triggerHide() {
    fadeOutElement(this.element, 1000)
  }
}