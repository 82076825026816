import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"
import rangePlugin from "flatpickr/dist/plugins/rangePlugin"
require("flatpickr/dist/flatpickr.css")
import { MetaVars } from "../lib/helpers"

export default class extends Controller {
  static targets = ["field"]
  static values = {
    maxDate: String,
    minDate: String,
    rangedBySelector: String
  }

  connect() {
    this.setupPicker()
  }

  disconnect() {
    this.flatpickrInstance &&
      this.flatpickrInstance.destroy()
  }

  setupPicker() {
    flatpickr(this.datepickerInput, this.options)
  }

  get flatpickrInstance() {
    return this.datepickerInput._flatpickr
  }

  get datepickerInput() {
    return this.hasFieldTarget ? this.fieldTarget : this.element
  }

  clear(event) {
    event.preventDefault()

    this.flatpickrInstance &&
      this.flatpickrInstance.clear()
  }

  openCalendar(event) {
    event.preventDefault()

    this.flatpickrInstance &&
      this.flatpickrInstance.open()
  }

  get options() {
    return { ...this.baseOptions, ...this.rangeOptions }
  }

  get baseOptions() {
    return {
      altInput: true,
      altFormat: this.altFormat,
      dateFormat: "Y-m-d",
      maxDate: this.maxDateValue,
      minDate: this.minDateValue
    }
  }

  get rangeOptions() {
    if (!this.hasRangedBySelectorValue) return

    return ({
      "plugins": [new rangePlugin({ input: this.rangedBySelectorValue })]
    })
  }

  get altFormat() {
    // Convert date_format_string (i.e. %Y-%m-%d) to a
    // flatpickr compatible string
    // See https://flatpickr.js.org/formatting/
    return MetaVars.dateFormatString
      .replace(/%-d/g, "j") // convert non-padded day
      .replace(/%-m/g, "n") // convert non-padded month
      .replace(/\%/g, "") // convert all others
  }
}