import { Controller } from "@hotwired/stimulus"
import { MetaVars } from "../lib/helpers"

const empty = t => !!t

export default class extends Controller {
  static values = {
    vars: Array,
    separator: {
      type: String,
      default: "|"
    }
  }

  connect() {
    const text = this.varValues.join(this.separatorValue)
    this.element.innerText = text
  }

  get varValues() {
    return this
      .varsValue
      .map(varName => MetaVars[varName])
      .filter(empty)
  }
}

