import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["rating"]

  initialize() {
    this.ratingTarget.getSymbol = (value) => {
      return `<sl-button size="small" style="min-width: 2vw;">${value}</sl-button>`
    }
  }

  hide(_event) {
    this.element.remove()
  }
}