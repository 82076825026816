import { MetaVars } from "./helpers"

export const log = (...attrs) => {
  if (MetaVars.envProduction) return

  console.log(...Array.from(attrs || []))
}

export const error = (...attrs) => {
  console.error(...Array.from(attrs || []))
}

export const eventLog = name => document.addEventListener(name, log)
export const eventError = name => document.addEventListener(name, error)
