// app/javascript/controllers/pop_modal_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const urlParams = new URLSearchParams(window.location.search)
    const popModalUrl = urlParams.get('pop_modal_url')

    if (popModalUrl) {
      const link = document.createElement('a')
      link.href = popModalUrl
      link.dataset.turboFrame = 'remote_modal'
      link.dataset.turbo = 'true'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
