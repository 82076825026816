import { Controller } from "@hotwired/stimulus"
import { coerce } from "../lib/helpers"

const AUTOPLAY_KEY = "toolbox::autoplay-video"

export default class extends Controller {
  static targets = ["player", "autoplay"]

  initialize() {
    window.currentYoutubePlayer = null

    const tag = document.createElement('script')
    tag.src = "https://www.youtube.com/iframe_api"
    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

    this.onYouTubeIframeAPIReady = this.onYouTubeIframeAPIReady.bind(this)
    window.onYouTubeIframeAPIReady = this.onYouTubeIframeAPIReady

    this.lsAutoplaySetting = this.lsAutoplaySetting || false
  }

  connect() {
    this.setupVideo()
    this.setAutoplayToggle()
  }

  onYouTubeIframeAPIReady() {
    this.setupVideo()
    this.setAutoplayToggle()
  }

  setupVideo() {
    if (window.currentYoutubePlayer != null) {
      window.currentYoutubePlayer.destroy()
    }

    if (typeof (YT) === "undefined") {
      return
    }

    new YT.Player(this.playerTarget, {
      videoId: this.videoId,
      playerVars: {
        'playsinline': 1,
        'autoplay': this.autoplaySetting,
      },
      events: {
        'onReady': this.onPlayerReady
      }
    })
  }

  get videoId() {
    return this.data.get("id")
  }

  get autoplaySetting() {
    if (this.lsAutoplaySetting) {
      return 1
    } else {
      return 0
    }
  }

  get lsAutoplaySetting() {
    return coerce(
      localStorage.getItem(AUTOPLAY_KEY)
    )
  }

  set lsAutoplaySetting(value) {
    localStorage.setItem(AUTOPLAY_KEY, value.toString())
  }

  toggle() {
    this.lsAutoplaySetting = !this.lsAutoplaySetting
  }

  setAutoplayToggle() {
    if (this.hasAutoplayTarget) {
      this.autoplayTarget.checked = this.lsAutoplaySetting
    }
  }

  onPlayerReady(event) {
    window.currentYoutubePlayer = event.target
  }
}