import { Controller } from "@hotwired/stimulus"
import { show, hide, templateRenderer, interpolateValues } from "../lib/helpers"

export default class extends Controller {
  static targets = [
    "input",
    "template",
    "insertInto",
    "name",
    "renderedTemplate"
  ]

  static values = {
    textFileTooBig: String,
    maxFileSize: {
      type: Number,
      default: 52480000
    },
  }


  addFile(event) {
    const { target } = event
    const file = target.files[0]

    if (!this.validFile(file)) { return }

    // this._file = file
    this.renderTemplate(file)
    this.hideInput()
    this.setNameOnNameTarget(file)
  }

  submitForm(event) {
    event.preventDefault()
    const progressBar = Turbo.navigator.delegate.adapter.progressBar

    progressBar.setValue(0)
    progressBar.show()

    window.mrujs.disableElement(event)

    event.target.submit()
  }

  get maxFileSize() {
    return this.maxFileSizeValue
  }

  get maxFileSizeAsMb() {
    return Math.floor(this.maxFileSize / 1024 / 1024)
  }

  chooseNewFile() {
    if (this.hasRenderedTemplateTarget) {
      this.renderedTemplateTarget.remove()
    }

    if (this.hasNameTarget) {
      this.nameTarget.value = ""
    }

    if (this.hasInputTarget) {
      this.inputTarget.value = null
    }

    this.showInput()
  }

  hideInput() {
    hide(this.inputTarget)
    this.inputTarget.removeAttribute("required")
  }

  showInput() {
    show(this.inputTarget)
    this.inputTarget.setAttribute("required", true)
  }

  setNameOnNameTarget(file) {
    if (this.hasNameTarget && !this.nameTarget.value) {
      this.nameTarget.value = file.name
    }
  }

  validFile(file) {
    if (file.size <= this.maxFileSize) {
      return true
    }

    const transformedErrorMessage = interpolateValues(this.textFileTooBigValue, {
      "max_file_size": this.maxFileSizeAsMb
    })
    alert(transformedErrorMessage)
    this.chooseNewFile()
    return false
  }

  renderTemplate(file) {
    const node = templateRenderer(this.templateTarget, {
      "#name": file.name
    })

    this.insertIntoTarget.append(node)
  }
}