import { Controller } from "@hotwired/stimulus"
import sparkline from "@fnando/sparkline"
import { iso8601Parse } from "../lib/date_helpers"
import { show, hide, removeAllChildren, addClass } from "../lib/helpers"

const sortDatesDesc = (a, b) => a.date - b.date
const hasNaNValue = i => isNaN(i.value)

export default class extends Controller {
  static targets = ["point", "container", "tooltip"]

  get tooltip() {
    if (this._tooltip) { return this._tooltip }

    let tooltipEl = document.querySelector("span.sparkline-tooltip")

    if (!tooltipEl) {
      tooltipEl = document.createElement('span')
      tooltipEl.classList.add("sparkline-tooltip", "d-none")
      document.body.appendChild(tooltipEl)
    }

    this._tooltip = tooltipEl

    return tooltipEl
  }

  connect() {
    if (this.points.find(hasNaNValue)) {
      // don't add sparkline because we have NaN
      return
    }

    if (!this.hasContainerTarget) {
      return
    }

    addClass(this.containerTarget, this.containerTarget.dataset.color)
    sparkline(this.containerTarget, this.points, this.options)
  }

  get points() {
    return this
      .pointTargets
      .map(point => {
        const { date, value, label } = point.dataset

        return {
          date: iso8601Parse(date),
          value: parseFloat(value),
          label
        }
      })
      .sort(sortDatesDesc)
  }

  get options() {
    return {
      onmousemove: this.mousemove.bind(this),
      onmouseout: this.mouseout.bind(this)
    }
  }

  mousemove(event, dataPoint) {
    const { label } = dataPoint
    const { clientX, clientY } = event
    const offset = 10 // the offset moves it away from the mouse cursor
    // and makes the movement smoother (i.e. doesn't
    // trigger unnecessary mouseout events)

    this.tooltip.textContent = label
    this.tooltip.style.left = `${clientX + offset}px`;
    this.tooltip.style.top = `${clientY + offset}px`;

    show(this.tooltip)
  }

  mouseout() {
    hide(this.tooltip)
  }

  disconnect() {
    if (this.hasContainerTarget) {
      removeAllChildren(this.containerTarget)
    }
  }
}
