import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  static values = {
    side: {
      type: String,
      default: "right"
    },
    scroll: {
      type: Boolean,
      default: true
    },
    keyboard: {
      type: Boolean,
      default: true
    },
    backdrop: {
      type: Boolean,
      default: false
    }
  }

  static targets = ["fixedTop", "scrollable"]

  initialize() {
    this.fixScrollableHeight = this.fixScrollableHeight.bind(this)
    this.afterHidden = this.afterHidden.bind(this)
  }

  connect() {
    this._bsOffcanvas = new bootstrap.Offcanvas(this.element, this._offCanvasOptions)
    document.addEventListener("scroll", this.fixScrollableHeight)
    this.element.addEventListener("hidden.bs.offcanvas", this.afterHidden)
    this.show()
  }

  disconnect() {
    this.hide()
    document.removeEventListener("scroll", this.fixScrollableHeight)
    this.element.removeEventListener("hidden.bs.offcanvas", this.afterHidden)

    this._bsOffcanvas.dispose()
    this._bsOffcanvas = null
  }

  show() {
    this.fixScrollableHeight()
    this._bsOffcanvas.show()
    this.element.parentElement.setAttribute("aria-hidden", "false")
  }

  hide() {
    this._bsOffcanvas.hide()
  }

  fixScrollableHeight() {
    if (!this.fixedTopTarget) return

    const maxHeight = window.innerHeight - this.fixedTopTarget.getBoundingClientRect().bottom
    this.scrollableTarget.style.overflowY = "auto"
    this.scrollableTarget.style.maxHeight = `${maxHeight}px`
  }

  teardown() {
    this.hide()
  }

  afterHidden() {
    this.element.parentElement.setAttribute("aria-hidden", "true")
  }

  get _offCanvasOptions() {
    return {
      scroll: this.scrollValue,
      backdrop: this.backdropValue,
      keyboard: this.keyboardValue
    }
  }
}