import { BaseModal } from "./base_modal"

export default class extends BaseModal {
  static targets = [
    "baselineStartAt",
    "baselineValue"
  ]

  showModal(event) {
    super.showModal(event)

    const { baselineValue, baselineStartAt } = event.relatedTarget.dataset

    this.baselineValueTarget.value = baselineValue
    this.baselineStartAtTarget._flatpickr.setDate(baselineStartAt)
  }
}