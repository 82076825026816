import { Controller } from "@hotwired/stimulus"

import { createFragment, hide, show } from "../lib/helpers"

export default class extends Controller {
  static targets = ['content', 'moreButton', 'lessButton']
  static values = {
    truncateLength: {
      type: Number,
      default: 30
    },
    truncateOmission: {
      type: String,
      default: "…"
    },
    truncatedContent: String,
    defaultToShowMore: Boolean
  }

  initialize() {
    this.originalContent = this.contentTarget.innerHTML
    this.truncatedContentValue = this.truncatedContentValue || this.textTruncatedContent()
  }

  connect() {
    if (!this.shouldTruncate) {
      this.hideAllButtons()

      return
    }

    this.render()
  }

  disconnect() {
    this.contentTarget.innerHTML = this.originalContent;
  }

  render() {
    if (this.defaultToShowMoreValue) {
      this.showMore()
    } else {
      this.showLess()
    }
  }

  get shouldTruncate() {
    const originalContentLength = createFragment(this.contentTarget.innerHTML).textContent.trim().length
    const truncatedContentLength = createFragment(this.truncatedContentValue).textContent.trim().length

    return (originalContentLength > truncatedContentLength)
  }

  showMore() {
    this.contentTarget.innerHTML = this.originalContent

    hide(this.moreButtonTarget)
    show(this.lessButtonTarget)
  }

  showLess() {
    this.contentTarget.innerHTML = this.truncatedContentValue

    hide(this.lessButtonTarget)
    show(this.moreButtonTarget)
  }

  hideAllButtons() {
    hide(this.moreButtonTarget)
    hide(this.lessButtonTarget)
  }

  textTruncatedContent() {
    return this.contentTarget.textContent.substring(0, this.truncateLengthValue) + this.truncateOmissionValue
  }
}
