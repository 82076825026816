import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["slider", "checkbox"]

  changeCheckbox(event) {
    const { checked } = event.target

    const value = checked ? 100 : 0
    this.sliderTarget.value = value
    this.sliderTarget.updateLabel && this.sliderTarget.updateLabel()
  }

  changeSlider(event) {
    const { value } = event.target
    const checked = value == "100"

    this.checkboxTarget.checked = checked
  }
}