
import "@shoelace-style/shoelace/dist/themes/light.css"
import "@shoelace-style/shoelace/dist/components/button/button.js"
import "@shoelace-style/shoelace/dist/components/skeleton/skeleton.js"
import "@shoelace-style/shoelace/dist/components/switch/switch.js"
import "@shoelace-style/shoelace/dist/components/option/option.js"
import "@shoelace-style/shoelace/dist/components/popup/popup.js"
import "@shoelace-style/shoelace/dist/components/tag/tag.js"
import "@shoelace-style/shoelace/dist/components/icon/icon.js"
import "@shoelace-style/shoelace/dist/components/icon-button/icon-button.js"
import "@shoelace-style/shoelace/dist/components/rating/rating.js"
import "@shoelace-style/shoelace/dist/components/color-picker/color-picker.js"
import "@shoelace-style/shoelace/dist/components/select/select.js"
import { setBasePath } from "@shoelace-style/shoelace/dist/utilities/base-path.js"

// Fix up load value on hard refresh (i.e. DOMContentLoaded)
document.addEventListener("DOMContentLoaded", function () {
  Array.from(document.querySelectorAll("sl-select")).forEach(slSelect => {
    const newValue = slSelect.attributes.value.value.split(" ")

    if (slSelect.value != newValue) slSelect.value = newValue
  })
})

// Set the base path to the folder you copied Shoelace"s assets to
setBasePath("dist/shoelace")

import "../../assets/stylesheets/components/_shoelace.scss"