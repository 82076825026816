import { BaseModal } from "./base_modal"

export default class extends BaseModal {
  static targets = [
    "targetEndAt",
    "targetValue"
  ]

  showModal(event) {
    super.showModal(event)

    const { targetValue, targetEndAt } = event.relatedTarget.dataset

    this.targetValueTarget.value = targetValue
    this.targetEndAtTarget._flatpickr.setDate(targetEndAt)
  }
}