import { Controller } from "@hotwired/stimulus"

import Reveal from 'reveal.js'
import { enterFullscreen } from '../../assets/vendor/reveal.js/js/utils/util'
import "../../assets/vendor/reveal.js/css/reveal.css"
import "../../assets/vendor/reveal.js/css/theme/source/black.css"
import "../../assets/vendor/reveal.js/css/theme/source/black.css"
import "../../assets/stylesheets/reveal-overrides.css"

export default class extends Controller {
  static targets = ["root"]
  static values = {
    embedded: { type: Boolean, default: true },
    overview: { type: Boolean, default: false }
  }


  connect() {
    this.setupPresentation()
  }

  setupPresentation() {
    if (this._presentation) { return }

    this._presentation = new Reveal()
    this._presentation.initialize(
      {
        embedded: this.embeddedValue,
        overview: this.overviewValue
      }
    )
  }

  disconnect() {
    if (!this._presentation) { return }

    this._presentation.destroy()
    this._presentation = null
  }

  goFullscreen(event) {
    event.preventDefault()
    this.setupPresentation()

    enterFullscreen(this._presentation.getViewportElement())
  }
}
